
import '@/assets/styles/imports/index-cap.sass'
import { Component, Vue } from 'vue-property-decorator'
import api from '@/api'
import { VehicleInterface } from '@/interfaces/vehicle'

@Component
export default class TheIndexCap extends Vue {
  params = {
    type: {
      name: 'type',
      id: 0,
      list: [] as Array<VehicleInterface.Type>,
    },
    brand: {
      name: 'brand',
      id: 0,
      list: [] as Array<VehicleInterface.Brand>,
    },
    model: {
      name: 'model',
      id: 0,
      list: [] as Array<VehicleInterface.Model>,
    },
    engine: {
      name: 'engine',
      id: 0,
      list: [] as Array<VehicleInterface.Engine>,
    },
    ecu: {
      name: 'ecu',
      id: 0,
      list: [] as Array<VehicleInterface.Ecu>
    }
  }

  getTypes () {
    api.vehicle.getVehicleType()
    .then(types => {
      this.params.type.list = types
      types.length === 1 ? this.params.type.id = types[0].id : this.params.type.id = 0
    })
    .then(() => this.changeSelect('type'))
  }

  getBrands () {
    api.vehicle
      .getVehicleBrand({ typeId: this.params.type.id })
      .then(brands => {this.params.brand.list = brands; brands.length == 1? this.params.brand.id=brands[0].id : this.params.brand.id=0;}).then(()=>this.changeSelect('brand'));
  }

  getModels () {
    api.vehicle
      .getVehicleModel({ typeId: this.params.type.id, brandId: this.params.brand.id })
      .then(models => {this.params.model.list = models; models.length == 1? this.params.model.id = models[0].id : this.params.model.id = 0;}).then(()=>this.changeSelect('model'));
  }

  getEngines () {
    api.vehicle
      .getVehicleEngine({
        typeId: this.params.type.id,
        brandId: this.params.brand.id,
        modelId: this.params.model.id
    }).then(engines => {this.params.engine.list = engines; engines.length == 1? this.params.engine.id = engines[0].id : this.params.engine.id = 0;}).then(()=>this.changeSelect('engine'));
  }

  getEcus () {
    api.vehicle.getVehicleEcu({
      typeId: this.params.type.id,
      brandId: this.params.brand.id,
      modelId: this.params.model.id,
      engineId: this.params.engine.id
    }).then(ecus => {this.params.ecu.list = ecus; ecus.length == 1? this.params.ecu.id = ecus[0].id : this.params.ecu.id = 0});
  }

  changeSelect (field: string) {
    this.cleanNextField(field)
    if (field === 'type') this.getBrands()
    else if (field === 'brand') this.getModels()
    else if (field === 'model') this.getEngines()
    else if (field === 'engine') this.getEcus()
  }

  cleanNextField (field: string): void {
    let clean = false
    for (const param in this.params) {
      if (clean) {
        this.params[param].id = 0
        this.params[param].list = []
      }
      else if (field === param) clean = true
    }
  }

  sendForm () {
    this.$router.push({
      name: 'Prices',
      params: { lang: this.$route.params.lang },
      query: {
        type: this.params.type.id.toString(),
        brand: this.params.brand.id.toString(),
        model: this.params.model.id.toString(),
        engine: this.params.engine.id.toString(),
        ecu: this.params.ecu.id.toString()
      }
    })
  }

  created () {
    this.getTypes()
  }
}
