import {PaymentInterface} from "@/interfaces/payment";

export default class PaymentMethods {
  private readonly _url: string

  constructor (url: string) {
    this._url = url
  }

  public async stockFakePay (firmwareId: number, amount: number): Promise<string|null> {
    const response = await fetch(
      `${this._url}/api/YaPayment/StockFakePay`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ firmwareId, amount })
      })
    if (!response.ok) return null
    const data = await response.json()
    if (data.success) return data.success
    if (data.failure) console.warn(data.failure)
    return null
  }

  public async getTaskStatus (taskId: number): Promise<string|null> {
    //vadim
    const response = await fetch(`${this._url}/api/YaPayment/GetTaskStatus?taskId=${taskId}`,{
      method: 'Get',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include'
    })
    if (!response.ok) return null
    const data = await response.json()
    if (data.success) return data.success
    if (data.failure) console.warn(data.failure)
    return null
  }

  public async getStockStatus (firmwareId: number): Promise<string|null> {
      //vadim
    const response = await fetch(`${this._url}/api/YaPayment/GetStockStatus?firmwareId=${firmwareId}`,{
      method: 'Get',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include'
    })
    if (!response.ok) return null
    const data = await response.json()
    if (data.success) return data.success
    if (data.failure) console.warn(data.failure)
    return null
  }

  public async getPaymentScriptParams<T> (taskId: number, provider: T): Promise<null|PaymentScriptParams<T>> {
    const response = await fetch(`${this._url}/api/Payment/GetPaymentScriptParams`,{
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ taskId, provider })
    })
    if (!response.ok) return null
    const data = await response.json()
    if (data.success) return data.success
    if (data.failure) console.warn(data.failure)
    return null
  }

  public async getDefaultProvider (): Promise<null| PaymentProvider> {
    const response = await fetch(`${this._url}/api/Payment/GetDefaultProvider`,{
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include'
    })
    if (!response.ok) return null
    const data = await response.json()
    if (data.success) return data.success
    if (data.failure) console.warn(data.failure)
    return null
  }
}

export type PaymentProvider = "Robocassa" | "Prodamus"
export type PaymentScriptParams<T> = T extends "Robocassa" ? PaymentInterface.PaymentScriptParamsRobocassa : PaymentInterface.PaymentScriptParamsProdamus
