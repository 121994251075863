
import { Component, Vue } from 'vue-property-decorator'
import TheIndexCap from '@/components/home/TheIndexCap.vue'
import HowWorks from '@/components/HowWorks.vue'
import TheIndexInfo from '@/components/home/TheIndexInfo.vue'
import TheIndexWhat from '@/components/home/TheIndexWhat.vue'

@Component({
  components: {
    TheIndexCap,
    HowWorks,
    TheIndexWhat,
    TheIndexInfo
  },
  metaInfo() {
    return{
    title: this.$tc('caption'),
  }}
})

export default class HomePage extends Vue {

}
