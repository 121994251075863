import { VehicleInterface } from '@/interfaces/vehicle'
import Request = VehicleInterface.Request

export default class VehicleMethods {
  private readonly _url: string
  constructor (url: string) {
    this._url = url
  }

  async postData (url: string, params: Request) {
    const response = await fetch(
      `${this._url}${url}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(params)
      })
    if (!response.ok) return null
    const data = await response.json()
    if (data.failure) console.warn(data.failure)
    if (data.success) return data.success
    return null
  }

  public async getVehicle (params: Request = {}): Promise<ResponseVehicle> {
    const data = await this.postData('/api/Vehicle/GetVehicle', params)
    if (data) return data
    return { itemsCount: 0, items: [] }
  }

  public async getVehicleType (params: Request = {}): Promise<Array<VehicleInterface.Type>> {
    const data = await this.postData('/api/Vehicle/GetVehicleType', params)
    if (data) return data
    return []
  }

  public async getVehicleBrand (params: Request = {}): Promise<Array<VehicleInterface.Brand>> {
    const data = await this.postData('/api/Vehicle/GetVehicleBrand', params)
    if (data) return data
    return []
  }

  public async getVehicleModel (params: Request = {}): Promise<Array<VehicleInterface.Model>> {
    const data = await this.postData('/api/Vehicle/GetVehicleModel', params)
    if (data) return data
    return []
  }

  public async getVehicleEngine (params: Request = {}): Promise<Array<VehicleInterface.Engine>> {
    const data = await this.postData('/api/Vehicle/GetVehicleEngine', params)
    if (data) return data
    return []
  }

  public async getVehicleEcu (params: Request = {}): Promise<Array<VehicleInterface.Ecu>> {
    const data = await this.postData('/api/Vehicle/GetVehicleEcu', params)
    if (data) return data
    return []
  }

}

interface ResponseVehicle {
  itemsCount: number;
  items: Array<VehicleInterface.Vehicle>;
}
