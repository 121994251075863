import { VehicleInterface } from '@/interfaces/vehicle'
import Vehicle = VehicleInterface.Vehicle
import { TaskInterface } from '@/interfaces/task'
import Task = TaskInterface.Task
import Tasks = TaskInterface.Tasks
import Histories = TaskInterface.Histories

export default class TaskMethods {
  private readonly _url: string

  constructor (url: string) {
    this._url = url
  }

  public async getView (params: GetParams): Promise<Histories|null> {
    const response = await fetch(
      `${this._url}/api/Task/GetView`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(params)
      })
    if (!response.ok) return null
    const data = await response.json()
    if (data.success) return data.success
    if (data.failure) console.warn(data.failure)
    return null
  }

  public async get (params: GetParams): Promise<Tasks|null> {
    const response = await fetch(
      `${this._url}/api/Task/Get`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(params)
      })
    if (!response.ok) return null
    const data = await response.json()
    if (data.success) return data.success
    if (data.failure) console.warn(data.failure)
    return null
  }

  public async edit (params: EditParams): Promise<Task|null> {
    const response = await fetch(
      `${this._url}/api/Task/Edit`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(params)
      })
    if (!response.ok) return null
    const data = await response.json()
    if (data.success) return data.success
    if (data.failure) console.warn(data.failure)
    return null
  }

  public async preview (vehicle: Vehicle): Promise<Task|null> {
    const response = await fetch(
      `${this._url}/api/Task/Preview`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ vehicle })
      })
    if (!response.ok) return null
    const data = await response.json()
    if (data.success) return data.success
    if (data.failure) console.warn(data.failure)
    return null
  }

  public async save (task: Task): Promise<Task|null> {
    const response = await fetch(
      `${this._url}/api/Task/Save`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(task)
      })
    if (!response.ok) return null
    const data = await response.json()
    if (data.success) return data.success
    if (data.failure) console.warn(data.failure)
    return null
  }

  public async calcPriceV2 (params: Task): Promise<Task|null> {
    const response = await fetch(
      `${this._url}/api/Task/CalcPriceV2`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(params)
      })
    if (!response.ok) return null
    const data = await response.json()
    if (data.success) return data.success
    if (data.failure) console.warn(data.failure)
    return null
  }

  public async process (taskId: number): Promise<void> {
    const response = await fetch(
      `${this._url}/api/Task/Process`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ taskId })
      })
    if (!response.ok) return
    const data = await response.json()
    if (data.failure) console.warn(data.failure)
  }

  public async getDownloadLink (taskId: number): Promise<string|null> {
    const response = await fetch(
      `${this._url}/api/Task/GetDownloadLink`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ taskId })
      })
    if (!response.ok) return null
    const data = await response.json()
    if (data.success) return data.success
    if (data.failure) console.warn(data.failure)
    return null
  }
}

interface GetParams {
  taskCreatedFrom?: string;
  taskCreatedTo?: string;
  searchPattern?: string;
  taskStatus?: string;
  skip?: number;
  take?: number;
}

interface EditParams {
  taskId?: number;
  fileId?: number;
  vehicle?: Vehicle;
}
