import storage from '@/storage'
import { UserInterface } from '@/interfaces/user'

export default class AuthorizeMethods {
  private readonly _url: string

  constructor (url: string) {
    this._url = url
  }

  private static cleanLoginInfo (): void {
    storage.user = null
  }

  public async getUser (): Promise<void> {
    const response = await fetch(
      `${this._url}/api/Authorize/GetUser`,
      { credentials: 'include' }
    )
    if (!response.ok) return AuthorizeMethods.cleanLoginInfo()
    const data = await response.json()
    if (data.success) return storage.user = data.success
    AuthorizeMethods.cleanLoginInfo()
  }

  public async login (email: string, password: string, rememberMe: boolean): Promise<{ login: boolean; message?: string}> {
    const response = await fetch(
      `${this._url}/api/Authorize/Login`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ email, password, rememberMe })
      })
    if (!response.ok) return { login: false }
    const data = await response.json()
    if (data.success) return { login: data.success }
    return { login: false, message: data.failure.message }
  }

  public async changePassword (old: string, newPass: string): Promise<{ refresh: boolean; errors?: string }> {
    const response = await fetch(
      `${this._url}/api/Authorize/ChangePassword`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ oldPassword: old, newPassword: newPass })
      })
    if (!response.ok) return { refresh: false }
    const data = await response.json()
    if (data.success) return { refresh: data.success }
    return { refresh: data.success, errors: data.failure.message }
  }

  public async register (params: RegisterParams): Promise<RegisterResponse> {
    const response = await fetch(
      `${this._url}/api/Authorize/Register`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(params)
      })
    if (!response.ok) return { register: false }
    const data = await response.json()
    if (data.success) return { register: data.success }
    return { register: false, errors: data.failure.errors }
  }

  public async update (profile: UserInterface.User): Promise<{ updated: boolean; errors?: string }> {
    const response = await fetch(
      `${this._url}/api/Authorize/Update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(profile)
      })
    if (!response.ok) return { updated: false }
    const data = await response.json()
    if (data.success) return { updated: data.success }
    if (data.failure) console.warn(data.failure)
    return { updated: false, errors: data.failure.errors }
  }

  public async logout (): Promise<void> {
    const response = await fetch(
      `${this._url}/api/Authorize/Logout`,
      {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' }
      })
    if (!response.ok) return AuthorizeMethods.cleanLoginInfo()
    const data = await response.json()
    if (data.success) return AuthorizeMethods.cleanLoginInfo()
    console.warn(data.failure)
  }

  public async isAuthenticated (): Promise<boolean> {
    const response = await fetch(
      `${this._url}/api/Authorize/IsAuthenticated`,
      {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' }
      })
    if (!response.ok) {
      AuthorizeMethods.cleanLoginInfo()
      return false
    }
    const data = await response.json()
    if (data.success) return true
    AuthorizeMethods.cleanLoginInfo()
    return false
  }

  public async forgotPassword (email: string): Promise<boolean> {
    const response = await fetch(
      `${this._url}/api/Authorize/ForgotPassword`,
      {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      })
    if (!response.ok) return false
    const data = await response.json()
    if (data.success) return data.success
    if (data.failure) console.warn(data.failure)
    return false
  }

  public async resetPassword (email: string, token: string, newPassword: string): Promise<boolean> {
    const response = await fetch(
      `${this._url}/api/Authorize/ResetPassword`,
      {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, token, newPassword })
      })
    if (!response.ok) return false
    const data = await response.json()
    if (data.success) return data.success
    if (data.failure) console.warn(data.failure)
    return false
  }

  public async verifyEmail (): Promise<boolean> {
    const response = await fetch(
      `${this._url}/api/Authorize/VerifyEmail`,
      {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({})
      })
    return response.ok
  }
}

export interface RegisterParams {
  password: string;
  passwordConfirm: string;
  email: string;
  phoneNumber: string;
  languageId: number;
  currencyId: number;
  countryId: number;
  userFirstName: string;
  userMiddleName?: string;
  userLastName: string;
  organizationName?: string;
  captureText?: string;
  isUserAgreed: boolean;
}

interface RegisterResponse {
  register: boolean;
  errors?: Array<{
    field: string;
    code: string;
    error: string;
  }>;
}
