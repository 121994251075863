
import '@/assets/styles/imports/header.sass'
import { Component, Vue } from 'vue-property-decorator'
import i18n from '@/i18n'
import api from '@/api'
import { UserInterface } from '@/interfaces/user'
import storage from '@/storage'

@Component({
  methods: {
    setLocation (lang: string) {

      if (lang === i18n.locale) return
      localStorage.lang = i18n.locale = lang

      const link = {
        name: this.$route.name ? this.$route.name : '',
        params: { ...this.$route.params, lang },
        query: this.$route.query
      }

      if (lang === 'en') delete link.params.lang
      this.$router.push(link)

    },
    async logout () {
      await api.authorize.logout()
      this.$router.go(0)
    }
  },
  computed: {
    currentLang () {
      return i18n.locale ? i18n.locale.toUpperCase() : 'EN'
    }
  }
})



export default class TheHeader extends Vue {
  user: UserInterface.User | null = null
  languages = ['en', 'ru']
  menu = [
    { name: 'Cars', text: 'cars' },
    { name: 'Prices', text: 'prices' },
    { name: 'News', text: 'news' },
    //{ name: 'Stocks', text: 'stocks' },
  ]

  checkUser () {
    this.user = storage.user
  }

  mounted () {
    this.checkUser()
    storage.watch(this.checkUser)
  }
}
