import { NewsInterface } from '@/interfaces/news'

export default class NewMethods {
  private readonly _url: string

  constructor (url: string) {
    this._url = url
  }

  public async item (id: number): Promise<NewsInterface.New|null> {
    const response = await fetch(`${this._url}/api/News/Get?newsId=${id}`)
    if (!response.ok) return null
    const data = await response.json()
    if (data.success) return data.success
    if (data.failure) console.warn(data.failure)
    return null
  }

  public async get (params: GetParams): Promise<NewsInterface.News|null> {
    const response = await fetch(
      `${this._url}/api/News/Get`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(params)
      })
    if (!response.ok) return null
    const data = await response.json()
    if (data.success) return data.success
    if (data.failure) console.warn(data.failure)
    return null
  }
}

interface GetParams {
  publishedFrom?: string;
  publishedTo?: string;
  newsType: 'NewFirmware'|string;
  searchPattern?: string;
  orderBy?: "Ascending"|"Decreasing";
  skip: number;
  take: number;
}
