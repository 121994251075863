import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomePage from '@/views/HomePage.vue'
import storage from '@/storage'
import api from '@/api'
import Meta from "vue-meta"
import i18n from "@/i18n";

Vue.use(VueRouter)
Vue.use(Meta, {
  refreshOnceOnNavigation: true,
});

const routes: Array<RouteConfig> = [
  {
    path: '/:lang?/agreement',
    name: 'Agreement',
    component: () => import('../views/AgreementPage.vue')
  },
  {
    path: '/:lang?/cars',
    name: 'Cars',
    component: () => import('../views/CarsPage.vue')
  },
  {
    path: '/:lang?/contacts',
    name: 'Contacts',
    component: () => import('../views/ContactsPage.vue')
  },
  {
    path: '/:lang?/download/:token',
    name: 'Download',
    component: () => import('../views/DownloadPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/:lang?/history',
    name: 'History',
    component: () => import('../views/HistoryPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/:lang?/subscriptions',
    name: 'subscriptions',
    component: () => import('../views/SubscriptionsPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/:lang?/login',
    name: 'Login',
    component: () => import('../views/LoginPage.vue'),
    meta: { checkAuth: true, guest: true }
  },{
    path: '/:lang?/mmc-flash',
    name: 'MMCFlash',
    component: () => import('../views/MMCFlashPage.vue'),
  }, {
    path: '/:lang?/mmc-order',
    name: 'MMCOrder',
    component: () => import('../views/MmcOrderPage.vue'),
  }, {
    path: '/:lang?/offer',
    name: 'Offer',
    component: () => import('../views/OfferPage.vue')
  }, {
    path: '/:lang?/order',
    name: 'Order',
    component: () => import('../views/OrderPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/:lang?/order/:id',
    name: 'OrderCustom',
    component: () => import('../views/OrderCustomPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/:lang?/policy',
    name: 'Policy',
    component: () => import('../views/PolicyPage.vue')
  },
  {
    path: '/:lang?/prices',
    name: 'Prices',
    component: () => import('../views/PricesPage.vue')
  },
  {
    path: '/:lang?/profile',
    name: 'Profile',
    component: () => import('../views/ProfilePage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/:lang?/registration',
    name: 'Registration',
    component: () => import('../views/RegistrationPage.vue'),
    meta: { checkAuth: true, guest: true }
  },
  {
    path: '/:lang?/reset-password',
    name: 'ResetPassword',
    component: () => import('../views/ResetPasswordPage.vue'),
    meta: { checkAuth: true, guest: true }
  },
  {
    path: '/:lang?/email-success',
    name: 'EmailSuccess',
    component: () => import('../views/EmailSuccessPage.vue')
  },
  // {
  //   path: '/stocks',
  //   name: 'Stocks',
  //   component: () => import('../views/StocksPage.vue')
  // },
  // {
  //   path: '/stocks/:id',
  //   name: 'Stock',
  //   component: () => import('../views/StockPage.vue')
  // },    
  {
     path: '/:lang/news/:id',
      name: 'New',
      component: () => import('../views/NewPage.vue')
    },
   {
      path: '/:lang/news',
      name: 'News',
      component: () => import('../views/NewsPage.vue')
    },
   {
    path: '/:lang/news/page/:page',
     name: 'NewsPage',
    component: () => import('../views/NewsPage.vue')
   },
   {
      path: '/:lang/news/:type',
      name: 'NewsType',
     component: () => import('../views/NewsPage.vue')
    },
   {
    path: '/:lang/news/:type/page/:page',
     name: 'NewsPageType',
     component: () => import('../views/NewsPage.vue')
   },

  {
    path: '/:lang?/404',
    name: '404',
    component: () => import('../views/Page404.vue')
  },
  {
    path: '/:lang?',
    name: 'Home',
    component: HomePage
  },
  {
    path: '*',
    redirect: '404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.params.lang === undefined || to.params.lang === '') {
    localStorage.lang = i18n.locale = 'en'
  } else if (to.params.lang === 'ru') {
    localStorage.lang = i18n.locale = 'ru'
  } else {
    return next({ name: '404' })
  }

  if (!to.meta || !to.meta.checkAuth) return next()

  const isAuth = await api.authorize.isAuthenticated()
  if (to.meta.guest) {
    if (isAuth) return next({ name: 'Home' })
    return next()
  }
  if (!isAuth) {
    storage.pageFromLogin = to
    if (to.params.lang === 'ru') return next({ name: 'Login', params: { lang: 'ru' }})
    return next({ name: 'Login' })
  }
  if (!storage.user) await api.authorize.getUser()
  else api.authorize.getUser().then()
  next()
})

export default router
