import { SupportInterface } from "@/interfaces/support";

export default class SupportMethods {
  private readonly _url: string;

  constructor(url: string) {
    this._url = url;
  }

  public async getMessageTypes(): Promise<void | Array<
    SupportInterface.SelectOption
  >> {
    const response = await fetch(`${this._url}/api/Support/GetMessageTypes`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({}),
    });
    const data = await response.json();
    return data.success;
  }

  public async open(
    taskId: number,
    messageTypeId: number,
    question: string
  ): Promise<void| null> {
    const response = await fetch(`${this._url}/api/Support/open`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        taskId: taskId,
        messageTypeId: messageTypeId,
        question: question,
      }),
    });
    const data = await response.json();
    return data.success;
  }
}
