import VehicleMethods from '@/api/vehicle'
import AuthorizeMethods from '@/api/authorize'
import DictionaryMethods from '@/api/dictionary'
import TaskMethods from '@/api/task'
import FirmwareMethods from "@/api/firmware"
import NewMethods from '@/api/new'
import PaymentMethods from '@/api/payment'
import SupportMethods from '@/api/support'
import SubscriptionMethods from '@/api/subscription'
import MmcStoreMethods from "@/api/mmc-store";
import MmcMethods from "@/api/mmc"
const baseURL: string = process.env.VUE_APP_API_URL || ''
if (!baseURL) console.warn('Base API url not found')

class API {
  public authorize: AuthorizeMethods = new AuthorizeMethods(baseURL)
  public vehicle: VehicleMethods = new VehicleMethods(baseURL)
  public dictionary: DictionaryMethods = new DictionaryMethods(baseURL)
  public task: TaskMethods = new TaskMethods(baseURL)
  public firmware: FirmwareMethods = new FirmwareMethods(baseURL)
  public news: NewMethods = new NewMethods(baseURL)
  public payment: PaymentMethods = new PaymentMethods(baseURL)
  public support: SupportMethods = new SupportMethods(baseURL)
  public subscription: SubscriptionMethods = new SubscriptionMethods(baseURL)
  public mmcStore: MmcStoreMethods = new MmcStoreMethods(baseURL)
  public mmc: MmcMethods = new MmcMethods(baseURL)
}

const api = new API()

export default api
