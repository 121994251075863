import { SupportInterface } from "@/interfaces/support";
import {PaymentProvider} from "@/api/payment";

export default class SubscriptionMethods {
  private readonly _url: string;

  constructor(url: string) {
    this._url = url;
  }

  public async processTask(taskId: number): Promise<void | null> {
    const response = await fetch(`${this._url}/api/Subscription/ProcessTask`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        taskId: taskId,
      }),
    });
    const data = await response.json();
    return data.success;
  }

  public async subscriptionInfo(): Promise<any | null> {
    const response = await fetch(
      `${this._url}/api/Subscription/GetUserSubscription`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({}),
      }
    );
    const data = await response.json();
    return data.success;
  }

  public async getPaymentScriptParams(id: any, provider: PaymentProvider): Promise<any | null> {
    const response = await fetch(
      `${this._url}/api/Subscription/GetPaymentScriptParams`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({
          subscriptionId: id,
          provider
        }),
      }
    );
    const data = await response.json();
    return data.success;
  }

  public async checkPayStatus(subscriptionId: any): Promise<any | null> {
    const response = await fetch(
      `${this._url}/api/Subscription/CheckPayStatus`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({
          subscriptionId: subscriptionId,
        }),
      }
    );
    const data = await response.json();
    return data.success;
  }

  public async GetUserSubscription(): Promise<any | null> {
    const response = await fetch(
      `${this._url}/api/Subscription/GetUserSubscription`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({}),
      }
    );
    const data = await response.json();
    return data.success;
  }
}
