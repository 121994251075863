
import "@/assets/styles/style.sass";
import { Component, Vue } from "vue-property-decorator";
import TheHeader from "@/components/_layout/TheHeader.vue";
import TheFooter from "@/components/_layout/TheFooter.vue";
import api from "@/api";
import ModalNote from '@/components/_modal/ModalNote2.vue'

@Component({
  components: {
    TheHeader,
    ModalNote,
    TheFooter,
  },
  metaInfo: {
    title:
      "ECUDTC - Автоматическая настройка прошивки для авто - Внесение изменений и удаление ошибок DTC",
    titleTemplate: "%s | ECUDTC",
  },
})
export default class App extends Vue {
  isModalToMMC = true
  created() {
    api.authorize.getUser();
  }

  goToMMC() {
    this.isModalToMMC = false
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore: Unreachable code error
    window.location = this.$route.params.lang ? "https://mmcflash.ru/" + this.$route.params.lang : 'https://mmcflash.ru/'
  }
}
