
import '@/assets/styles/imports/modal-note.sass'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ModalNote extends Vue {
  @Prop({ type: String, default: 'error' }) readonly type!: 'error'|'done'
  @Prop({ type: String, default: '' }) readonly ok!: string
  @Prop({ type: String, default: '' }) readonly no!: string

  eventOk () {
    this.$emit('ok')
  }

  eventNo () {
    this.$emit('no')
  }

  eventClose () {
    this.$emit('close')
  }
}
